import React from "react";
import PropTypes from "prop-types";
import { BannerContents } from "../../../components/shared/layout";
import { Button } from "../../../components/shared/actions";

export const BannersAcidentesPessoaisBemEstar = (props) => {
  const { images } = props;

  const handleClick = () => {
    window.location.href = "/acidentespessoais#valores-bem-estar";
  };

  return (
    <>
      <BannerContents {...props}>
        <BannerContents.Banner
          backgroundImage={images.bannerAcidentesPessoaisBemEstar}
          right
        >
          <Button
            className="btn-banner"
            blank
            secondary
            onClick={handleClick}
          >
            Compre agora
          </Button>
        </BannerContents.Banner>
      </BannerContents>
    </>
  );
};

BannersAcidentesPessoaisBemEstar.defaultProps = { images: {} };
BannersAcidentesPessoaisBemEstar.propTypes = {
  images: PropTypes.objectOf(Object),
};
export default BannersAcidentesPessoaisBemEstar;