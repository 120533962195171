/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { createImageElement } from "../../../asserts/images";
import icons from "../../../asserts/images/icons";
import { Button } from "../actions";
import { LINKS } from "../../../constants";

const plans = [
  { title: "Plano Mensal", value: "R$18,90", id: "Mensal" },
  { title: "Plano Anual (12 meses)", value: "R$199", id: "Anual" },
  { title: "Plano Trienal (36 meses)", value: "R$500", id: "Trienal" },
];

const FloatBanner = () => {
  const [checked, setChecked] = useState(false);
  const [checkedMobile, setCheckedMobile] = useState(false);
  const [iconVisible, setIconVisible] = useState(true);

  const toggle = () => {
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent);
    if (isMobileDevice) {
      setCheckedMobile(!checkedMobile);
    }
    setChecked(!checked);
    setIconVisible(!iconVisible);
  };

  return (
    <div className={checkedMobile ? "cvp-float-banner-mobile" : "cvp-float-banner"}>
      <div onClick={toggle} className="cvp-float-banner-button">
        <div className="d-flex align-items-center justify-content-center">
          Aproveite Plano Ideal
          {createImageElement(icons.chatFloatIcon, {
            className: iconVisible ? "cvp-float-banner-icon" : "cvp-float-banner-icon-closed",
          })}
        </div>
      </div>
      <div className={checked ? "float-banner-closed" : "float-banner-opened"}>
        {plans.map(plan => (
          <div className="float-plano" key={plan.id}>
            <p className="float-plano-titulo">{plan.title}</p>
            <p className="float-plano-valor">{plan.value}</p>
            <Button
              className="base-btn btn-secondary float-banner-btn"
              href={`${LINKS.autoCompraBemEstar}/${plan.id}`}
            >
              Contratar Agora
            </Button>
          </div>
        ))}
        <div className="float-plano-icone" onClick={toggle}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      </div>
    </div>
  );
};

export default FloatBanner;