import React from "react";
import PropTypes from "prop-types";
import { LINKS } from "@constants";
import { Col, Row } from "react-bootstrap";
import { Button } from "../../../components/shared/actions";
import { BannerContents } from "../../../components/shared/layout";
import imagensPrevidencia from '@asserts/images/previdencia/index';

export const BannerVida = (props) => {
  const { images } = props;

  return (
    <BannerContents {...props}>
      <BannerContents.Banner
        backgroundImage={images.bannerVida}
        group={["voce", "coberturas-assistencias", "resultado-sorteios"]}
        className="text-white"
      >
        <h3 className="d-md-inline-block">Seguro de vida</h3>
        <p className="text-body-md">
          Clientes CAIXA agora podem comprar o Seguro de Vida 100% online.
        </p>
        <p className="text-body-md">
          Pague no débito em conta Caixa e aproveite!
        </p>
        <Button
          className="text-center cvp-action-comprar"
          blank
          secondary
          href={LINKS.autoCompraVida}
          style={{ fontWeight: "bold" }}
        >
          Compre agora
        </Button>
      </BannerContents.Banner>
      <BannerContents.Banner
        backgroundImage={images.bannerBemEstarCampanha}
        group={["voce", "coberturas-assistencias", "resultado-sorteios"]}
        className="text-white banner-seguro-acidentes"
      >
        
        <h3 className="d-md-inline-block">SEGURO ACIDENTES PESSOAIS</h3>
        <div className="box-text"
>       <p className="text-body-md">
          GARANTA A PROTEÇÃO DA SUA FAMÍLIA EM CASO DE IMPREVISTOS.
        </p>
        </div> 
        <div className="box-value">
        <p className="topValue">A PARTIR DE</p>
        <h3 className="d-md-inline-block">R$ 0,55</h3>
        <p className="subValue">
          POR DIA.
        </p></div>
        <Button
          className="text-center cvp-action-comprar button"
          blank
          secondary
          href={LINKS.campanhaBemEstar}
          style={{ fontWeight: "bold" }}
        >
          Contrate agora
        </Button>
      </BannerContents.Banner>
      <BannerContents.Banner
        backgroundImage={imagensPrevidencia.bannerAssistencias}
        className="text-blue"
        left
      >
        <Row>
          <Col xs={12} className="d-flex align-items-stretch">
          <h3 className="d-md-inline-block title-assistencias">SUSTENTABILIDADE AO SEU ALCANCE</h3>
          </Col>
          <Col xs={12}>
            <p className="text-body-md mt-0 mt-md-2 text-assistencias">
              Assistência que transforma o mundo ao seu redor, contribua para causas sociais e
              ambientais enquanto aproveita benefícios pesados para você. Seja a mudança que você quer ver.
            </p>
            <Button
              className="mt-2"
              blank
              secondary
              href={LINKS.guiaAssistencias2025}
            >
              Baixe o guia
            </Button>
          </Col>
        </Row>
      </BannerContents.Banner>
    </BannerContents>
  );
};

BannerVida.defaultProps = { images: {} };

BannerVida.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannerVida;
