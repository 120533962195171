import React from 'react';
import PropTypes from 'prop-types';
import { Section } from '@components/shared/pages';
import Campaign from '../../../components/blocosCustomizados/Campaign';
import { Link } from '../../../components/shared/actions';
import { LINKS } from '../../../constants';

const regulamento = 'o regulamento';

const conteudoCampanha = [
  {
    label: 'Campanha Blue Friday 2024',
    content: (
      <p>
        De 11/11/2024 a 29/11/2024, oferecemos 15% de desconto em novas contratações de seguros com pagamento mensal para os 
        produtos Vida da Gente, Vida Multipremiado, Vida Multipremiado Total, Vida Mulher, Vida Mulher Total, Vida Sênior, 
        Seguro Proteção Executiva e Vida Empresarial Global, válido durante toda a vigência do seguro. Para o produto Vida 
        Empresarial Global, o desconto de 15% também estará disponível na modalidade de pagamento antecipado. 
        Para mais informações, consulte o{' '}
        <Link href={LINKS.guiaCampanhaBlueFriday2024} label="aqui" />{' '}
        {regulamento}.
      </p>
    ),
    idCampanha: 'campanha-black-friday-2024',
  },
  
  {
    label: 'Campanha Mês dos Pais 2024',
    content: (
      <p>
        Durante o período de 02/08/2024 a 30/08/2024, disponibilizaremos 15% de desconto aos clientes 
        que contratarem novos certificados com periodicidade de pagamento mensal para os produtos 
        Vida da Gente, Vida Multipremiado, Vida Multipremiado Total e Seguro Proteção Executiva por 
        toda a vigência do seguro. Para mais informações, confira{' '}
        <Link href={LINKS.guiaDiaDosPaisCaixa2024} label="aqui" />{' '}
        {regulamento}.
      </p>
    ),
    idCampanha: 'dia-dos-pais-caixa-2024',
  },

  {
    label: 'Aniversário Caixa 2025',
    content: (
      <p>
        Durante o período de 13/01/2025 a 31/01/2025, disponibilizaremos 15% de
        desconto para os clientes que contratarem os produtos Vida da Gente,
        Vida Mulher, Vida Mulher Total, Vida Multipremiado Super, Vida
        Multipremiado Total, Vida Sênior e Vida Empresarial Global por toda a
        vigência do seguro. Para mais informações, confira{' '}
        <Link href={LINKS.guiaAniversarioCaixa2025} label="aqui" />{' '}
        {regulamento}.
      </p>
    ),
    idCampanha: 'aniversario-caixa-2025',
  },

  {
    label: 'Campanha Mês da Mulher 2024',
    content: (
      <p>
        Durante o período de 01/03/2024 a 29/03/2024, disponibilizaremos 15% de
        desconto para as clientes que contratarem novos certificados para os
        produtos Vida Mulher e Vida Mulher Total por toda a vigência do seguro.
        Para mais informações, confira{' '}
        <Link href={LINKS.guiaMesDaMulher2024} label="aqui" /> {regulamento}.
      </p>
    ),
    idCampanha: 'mes-da-mulher-2024',
  },

  {
    label: 'Quinzena do Consumidor 2024',
    content: (
      <p>
        Durante o período de 15/03/2024 e 29/03/2024, disponibilizaremos 15% de
        desconto para as clientes que contratarem novos certificados para os
        produtos Vida da Gente, Vida Multipremiado Super, Vida Multipremiado
        Total, Vida Sênior e Seguro Proteção Executiva por toda a vigência do
        seguro. Para mais informações, confira{' '}
        <Link href={LINKS.guiaQuinzenaDoConsumidor2024} label="aqui" />{' '}
        {regulamento}.
      </p>
    ),
    idCampanha: 'quinzena-do-consumidor-2024',
  },

  {
    label: 'Ações Promocionais e Campanhas anteriores a 2024',
    content: (
      <p>
        Para conferir as informações e regulamentos das ações promocionais e
        campanhas anteriores a 2024,
        <Link href="/segurodevida/acoes-promocionais" label="clique aqui" />.
      </p>
    ),
    idCampanha: 'blue-friday',
  },
];

export const Campanha = ({ sectionTitle, campanhas }) => {
  return (
    <Campaign
      title={sectionTitle}
      contentCampaign={conteudoCampanha}
      campanhas={campanhas}
      right
    />
  );
};

Campanha.defaultProps = {
  campanhas: [],
};

Campanha.propTypes = {
  ...Section.specializedProps,
  campanhas: PropTypes.arrayOf(Object),
};

export default Campanha;
