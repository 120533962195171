import React from "react";
import VideoPlayer from "react-video-js-player";
import { Section } from "@components/shared/pages";
import { SeguridadeFaq } from "../../../components/blocosCustomizados";
import FaqButtonAreaCliente from "../../../components/shared/common/FaqButtonAreaCliente";
import {
  Link,
  PhoneLink,
} from "../../../components/shared/actions";
import Table from "../../../components/shared/table";
import { CONTENTS, LINKS } from "../../../constants";

const videoJsOptions = {
  pip: true,
  width: "max",
  height: "200",
};

const contentTableTempoPermanenciaPlano = () => {
  const header = [
    "Tempo de permanência no plano",
    "Taxa de Carregamento na Saída",
  ];

  const body = [
    {
      key:1,
      row: [
        { label: "Até 12 meses", props: {key:1} },
        { label: "1,50 %", props: {key:2} },
      ],
    },
    {
      key:2,
      row: [
        { label: "De 13 a 24 meses", props: {key:3} },
        { label: "1,00 %", props: {key:4} },
      ],
    },
    {
      key:3,
      row: [
        { label: "De 25 a 36 meses", props: {key:5} },
        { label: "0,50 %", props: {key:6} },
      ],
    },
    {
      key:4,
      row: [
        { label: "Acima de 36 meses", props: {key:7} },
            { label: "0,00 %", props: {key:8} },
      ],
    },
  ];

  return (
    <Table
      header={
        <tr>
          {header.map((obj) => (
            <th key={obj} color="primary">{obj}</th>
          ))}
        </tr>
      }
      responsive
      striped
      bordered
      hover
      className="text-center tabela-permanecia-plano"
    >
      {body.map((obj) => (
        <tr key={obj.key}>
          {obj.row.map((row) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <td {...row.props}> {row.label}</td>
          ))}
        </tr>
      ))}
    </Table>
  );
};

export const contentQuaisSaoTaxasCobradas = (
  <>
    <p>
      <strong>Taxa de administração:</strong> é o que cobramos para gerenciar
      seu dinheiro. Nossa taxa varia e o que vai determiná-la é a quantidade de
      dinheiro que você aplicou na previdência e o tipo de fundo de investimento
      que você escolheu.
    </p>

    <p>
      <strong>Taxa de carregamento:</strong> é a porcentagem descontada ao
      colocar ou retirar dinheiro do seu plano. Existe a taxa de entrada e a
      taxa de saída.
    </p>
    <p>
      <strong>Nós não cobramos taxa de entrada.</strong> E só cobramos a taxa de saída se você retirar seu dinheiro antes de completar 36 meses de plano.
      Veja nossa taxa de saída para novas contratações:
    </p>

    {contentTableTempoPermanenciaPlano()}
  </>
);
export const contentQuaisSaoTaxasCobradasCliente = (
  <>
    <p>
      <strong>Taxa de administração:</strong> é o que cobramos para gerenciar
      seu dinheiro. Nossa taxa varia e o que vai determiná-la é a quantidade de
      dinheiro que você aplicou na previdência e o tipo de fundo de investimento
      que você escolheu.
    </p>

    <p>
      <strong>Taxa de carregamento:</strong> é a porcentagem descontada ao
      colocar ou retirar dinheiro do seu plano. Existe a taxa de entrada e a
      taxa de saída.
    </p>
    <p>
      <strong>Nós não cobramos taxa de entrada.</strong> E só cobramos a taxa de saída se você retirar seu dinheiro antes de completar 36 meses de plano.
    </p>

  </>
);

export const contentBeneficioExclusivoMulheres = (
  <>
    <p>
      <strong>Gravidez Premiada: </strong> em caso de gestação múltipla (gêmeos,
      trigêmeos) concebida naturalmente, você receberá R$ 50 mil no nascimento
      dos bebês.
      <br /> Para usar esse benefício você deve ligar na nossa Central de
      Atendimento: <PhoneLink phone="0800 702 4000" />
    </p>
    <p>
      <strong>Prevenção Ginecológica:</strong> uma vez por ano, você poderá
      consultar gratuitamente com ginecologista credenciado, realizar o exame
      Papanicolau e ainda tem direito a retorno. Para consultar os médicos
      credenciados e agendar uma consulta, ligue na nossa Central de
      Atendimento: <PhoneLink phone="0800 702 4000" /> e informe sua cidade. Se
      na sua região não tiver nenhum médico credenciado, nossos consultores irão
      orientar você sobre o pedido de reembolso da consulta.
    </p>
    <p>
      Importante: os benefícios são válidos somente para mulheres na contratação
      de um plano VGBL.
    </p>
  </>
);
const faqItems = [
  {
    label: "Como baixar meu informe de Imposto de Renda?",
    content: (
      <>
        <p>
          Saiba <Link href="/informe-de-rendimentos" label="aqui" /> como baixar
          o seu informe.
        </p>
      </>
    ),
  },
  {
    label: "Resolva rápido. Confira os serviços que você faz online.",
    content: (
      <>
        <ul>
          <li>Visualiza seu número no sorteio e os resultados dos sorteios</li>
          <li>Emite 2ª via de boleto</li>
          <li>Realiza contribuições adicionais</li>
          <li>Solicita resgates</li>
          <li>Visualiza saldo e extrato</li>
          <li>Baixa Informes de Rendimentos e Contribuição</li>
          <li>Emite certificados</li>
          <li>Altera dados do plano e fundo de investimento</li>
          <li>Consulta beneficiários e assistências</li>
        </ul>
        <p>
          Acesse agora o seu plano na Área do Cliente ou baixe o nosso
          aplicativo Caixa Previdência. Caso não tenha cadastro, faça agora
          mesmo e utilize o mesmo login e senha para os dois ambientes.
        </p>
        <p>
          Preparamos um vídeo para te ajudar a utilizar o APP Caixa Previdência.
          Assista abaixo:
        </p>
        <VideoPlayer id="video" src={LINKS.linkVideoGuia}  {...videoJsOptions} />
      </>
    ),
  },
  {
    label: "Como resgatar minha previdência?",
    content: (
      <>
        <p>Veja como solicitar um resgate: </p>
        <ul>
          <li>Resgates até 10 mil devem ser solicitados na Área do Cliente;</li>
          <li>
            Para resgatar a partir de R$ 10.000,01, entre em contato com a nossa
            Central de Serviços e Relacionamento:{" "}
            <PhoneLink phone="08007024000" />;
          </li>
          <li>
            Resgates de planos empresariais - parte participante, entre em
            contato com a nossa Central de Serviços e Relacionamento:{" "}
            <PhoneLink phone="08007024000" />;
          </li>
          <li>
            Resgates de planos empresariais - parte empresa, procure uma{" "}
            <Link
              blank
              href={LINKS.encontreAgenciaCaixa}
              label="agência da Caixa"
            />
            .
          </li>
        </ul>
      </>
    ),
  },
  {
    label: "Benefício fiscal",
    content: (
      <>
        <p>
          O benefício fiscal permite que o valor investido no PGBL possa ser
          declarado no IR, assim como você faz com suas despesas médicas e
          escolares. Dessa forma, você paga menos impostos ou tem uma
          restituição maior.
        </p>
        <p>
          Se você faz a declaração de IR completa, pode deduzir até 12% da sua
          renda bruta anual com o PGBL.
        </p>
        <p>
          <Link
            href="/previdencia/simulador-beneficio-fiscal"
            label="Simule aqui seu benefício fiscal"
          />
        </p>
      </>
    ),
  },
  {
    label: "Quais são as taxas cobradas",
    content: contentQuaisSaoTaxasCobradasCliente,
  },
  {
    label: "Rendimento dos fundos de investimento",
    content: (
      <p>
        {" "}
        A quantia que você contribui para a previdência é aplicada em fundos de
        investimento e administrada por especialistas para buscar o melhor
        rendimento para seu dinheiro. Consulte aqui a{" "}
        <Link
          href="/previdencia/rendimento-dos-fundos"
          label="tabela de rentabilidade"
        />{" "}
        com os diferentes tipos de fundos.
      </p>
    ),
  },
  {
    label: "Como fazer a portabilidade do meu plano?",
    content: (
      <p>
        Se por algum motivo os nossos planos não estiverem atendendo às suas
        necessidades, você pode levar o seu plano da Caixa Vida e Previdência
        para outra instituição. Antes de fazer isso fale com a gente, pois
        estamos sempre à disposição caso queira tirar alguma dúvida ou ajustar
        seu plano, ok? Para fazer a portabilidade para outra instituição, você
        deve aguardar o período de pelo menos 60 dias da contratação do plano.
      </p>
    ),
  },
  {
    label: "Acesse o seu regulamento",
    content: (
      <p>
        Para consultar e baixar o regulamento do seu plano tenha em mãos as
        informações do seu certificado. Você pode encontrá-las na Área do
        Cliente, no seu extrato ou no seu contrato físico.
      </p>
    ),
  },
  {
    label: "Como utilizar os benefícios exclusivos para mulheres?",
    content: contentBeneficioExclusivoMulheres,
  },
  {
    label: "Como cancelar minha previdência?",
    content: (
      <>
        <p>
          Para cancelar a sua previdência você pode ligar pra gente nos
          seguintes telefones:
        </p>
        <p>
          <h6 className="central-atendimento-cancelar">
            Central de Atendimento
          </h6>
          <PhoneLink phone="0800 702 4000" />
        </p>
        <p>
          <h6 className="sac-cancelar">SAC</h6>
          <PhoneLink phone="0800 702 4280" />
        </p>
        <p>
          Se preferir, pode
          procurar uma{" "}
          <Link blank href={LINKS.encontreAgenciaCaixa} label="agência CAIXA" />{" "}
          mais próxima.
        </p>
      </>
    ),
  },
  CONTENTS.comoMeProtegerContraFraudes,
  CONTENTS.guiaSinistroFaqItem,
  CONTENTS.guiaAssistenciasFaqItem,
  {
    label: "O que é o ITCMD?",
    content: (
      <>
        <p>
          O Imposto sobre Transmissão Causa Mortis e Doação (ITCMD) é um tributo
          brasileiro aplicado sobre heranças e doações. É de competência
          estadual e do Distrito Federal sua aplicação, alíquotas, cálculos e
          procedimentos, conforme previsto na Constituição Federal (artigo 155,
          I e § 1º), podendo variar de estado para estado.
        </p>
        <p>
          Nos planos de previdência VGBL, este imposto pode incidir a tributação
          e por isso, recomendamos consultar a Lei referente ao seu Estado de
          atuação ou residência para confirmar os valores e obrigações.
        </p>
        <p>
          Em Minas Gerais, o Conselho de Contribuintes, entende que os planos
          VGBL de previdência privada são verdadeiras aplicações financeiras e,
          por isso, em caso de morte do titular, deve incidir o ITCMD, cabendo a
          nós, seguradora, recolher do beneficiário e repassar ao Estado,
          respeitando as normas do CC/MG.
        </p>
      </>
    ),
  },
  {
    label: "Como gerencio os beneficiários do meu plano?",
    content: (
      <>
        <p>
          Você pode incluir e gerenciar os beneficiários do seu plano de
          previdência a qualquer momento acessando o App Caixa Previdência. É
          tudo bem fácil e prático! Para te ajudar nesse processo, criamos um
          guia que você pode{" "}
          <Link
            blank
            href={LINKS.guiaInclusaoBeneficiarios}
            label="acessar aqui"
          />
          .
        </p>
      </>
    ),
  },
];

export const JaSouClientePrevidencia = ({ sectionTitle }) => {
  return (
    <SeguridadeFaq
      title={sectionTitle}
      faqItems={faqItems}
      defaultActiveKey={2}
      faqFooterContent={<FaqButtonAreaCliente />}
      right      
    />
  );
};

JaSouClientePrevidencia.propTypes = {
  ...Section.specializedProps,
};

export default JaSouClientePrevidencia;
