import React from "react";
import PropTypes from "prop-types";
import Apresentacao from "../../../components/blocosCustomizados/Apresentacao";

export const MicroSeguroAmparo = (props) => {
  const { images, sectionTitle } = props;
  return (
    <>
      <Apresentacao
        image={images.acidentesPessoaisApresentacaoDireita}
        left
        imageColumn={{ span: 4, offset: 2 }}
        title={sectionTitle}
        titleCenter
        contentColumn={{
          className: "d-flex align-items-center basic-color-blue marge",
          xs: 12,
          lg: { span: 5, offset: 0 },
        }}
      />
      <p>
        Principalmente na hora que mais precisamos, você pode contar com as
        soluções do Seguro Amparo. Organizar e pagar um funeral não
        precisa ser uma tarefa para sua família. E em caso de morte por
        acidente, garantimos um pagamento extra ao(s) beneficiário(s).
      </p>
      <p>
        A contratação de um serviço funerário talvez seja caro se a escolha não
        for certa. Com o Seguro Amparo, você tem muito mais pagando muito
        menos, uma vez ao ano.
      </p>
      <br />
      <p>
        <strong>Quem pode adquirir?</strong>
      </p>
      <p>
        Pessoas com idade entre <strong>16 e 70 anos podem contratar </strong>o
        Seguro Amparo.
      </p>
    </>
  );
};

MicroSeguroAmparo.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
  images: PropTypes.objectOf(Object).isRequired,
};

export default MicroSeguroAmparo;
