import envs from "./envs";

const createRedirects = (paths, basePath) => {
  return paths.flatMap((path) => [
    [path, `${basePath}${path}.html`],
    [`${path}.html`, `${basePath}${path}.html`]
  ]);
};

const customRedirects = [
  ...createRedirects(["/index", "/apoiovida", "/prevjuntos"], "/maisfuturo"),
  ["/maisfuturo", "/maisfuturo/index.html"],
  ...createRedirects(["/index"], "/bemestar"),
  ["/bemestar", "/bemestar/index.html"]
];

const staticRedirects = [
  ["/", envs.homePortal],
  [
    "/seguro-apoio-familia-caixa-tem",
    `${envs.hostStaticCdn}/areaaberta/CondicoesGerais/Vida/SeguroApoioFamiliaMicrosseguroCaixaTem/CaixaVidaePrevidencia-Condicoes-Gerais-Apoio-Família-Caixa-Tem.pdf`
  ]
];

export default [...staticRedirects, ...customRedirects];
