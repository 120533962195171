import React from "react";
import { Section } from "@components/shared/pages";
import { SeguridadeFaq } from "../../../components/blocosCustomizados";
import FaqButtonAreaCliente from "../../../components/shared/common/FaqButtonAreaCliente";
import { CONTENTS } from "../../../constants";

const faqItems = [
  {
    label: "Quanto custa o Seguro Dívida Zero?",
    content: (
      <>
        <p>O valor do seguro varia de acordo com:</p>
        <ul>
          <li>Valor do empréstimo;</li>
          <li>Prazo do empréstimo, e;</li>
          <li>Idade do contratante.</li>
        </ul>
      </>
    ),
  },
  {
    label: "Quais são as formas de pagamento?",
    content: (
      <>
        <p>Existem duas formas de pagar o seu seguro prestamista. São elas:</p>
        <p>
          <strong>À vista</strong>, via boleto ou débito em conta Caixa.
        </p>
        <p>
          <strong>Mensalmente</strong>, incluído na parcela do seu empréstimo.
        </p>
      </>
    ),
  },
  CONTENTS.guiaSinistroFaqItem,
  CONTENTS.guiaAssistenciasFaqItem,
];

const SaibaMaisPrestamista = ({ sectionTitle, sectionName }) => {
  return (
    <SeguridadeFaq
      title={sectionTitle}
      faqItems={faqItems}
      right
      faqFooterContent={sectionName === "duvidas"?<FaqButtonAreaCliente />:""}
    />
  );
};

SaibaMaisPrestamista.propTypes = {
  ...Section.specializedProps,
};

export default SaibaMaisPrestamista;
