import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const StateInput = ({ label, state, formikBag, ...props }) => (
  <InputControl
    inputStyle={{ minHeight: "50px" }}
    controlId="formSinistro.reclamante.estado"
    label={label}
    placeHolder="Digite o estado"
    name="reclamante.estado"
    value={state}
    {...formikBag}
    {...props}
  />
);

StateInput.propTypes = {
  state: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default StateInput;
