import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { LINKS } from "@constants";
import image, { createImageElement } from "@asserts/images";
import { Button } from "../../../components/shared/actions";
import { BannerContents } from "../../../components/shared/layout";

export const BannerAmparo = (props) => {
  const { images } = props;

  return (
    <>
      <BannerContents {...props}>
        <BannerContents.Banner
          backgroundImage={images.amparoBanner}
          group="voce"
          className="text-white"
          right
        >
          <Row>
            <Col xs={12} className="d-flex align-items-stretch">
              {createImageElement(image.iconHeart, {
                className: "d-none d-md-inline-block cvp-icon-banner",
              })}
              <h3 className="d-md-inline-block">Seguro Amparo</h3>
            </Col>
            <Col xs={12}>
              <p className="text-body-md mt-0 mt-md-2">
                Deixe um apoio financeiro para a família em sua ausência. Um
                jeito deles lidarem apenas com a saudade.
              </p>
              <Button
                className="mt-3"
                blank
                secondary
                href={LINKS.autoCompraAmparo}
              >
                Comprar agora
              </Button>
            </Col>
          </Row>
        </BannerContents.Banner>
      </BannerContents>
    </>
  );
};

BannerAmparo.defaultProps = { images: {} };

BannerAmparo.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannerAmparo;
