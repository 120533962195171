import { useState } from "react";
import PropTypes from "prop-types";

/**
 * This service hook will fetch lottery results from an API endpoint.
 *
 * @component
 * @return  {UseDrawResultHook}
 *
 * @typedef    {Object}    UseDrawResultHook
 *  @property  {function}  getDrawResults     Function used for API call
 *  @property  {number}    status             HTTP status code
 *  @property  {Object}    data               Raw response data
 *  @property  {boolean}   loading            Loading indicator
 *  @property  {boolean}   error              Error indicator
 */
const useDrawResult = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [data, setData] = useState();

  /**
   * Fetches lottery results from API
   * @param   {string}   drawProduct     Product code
   * @param   {string}   drawYear        Starting year for lookup
   * @param   {string}   user            Service user id
   */
  const getDrawResults = async () => {
    setLoading(true);
    
      setStatus(200);
      setData(null);
      setLoading(false);
  };

  return { getDrawResults, status, data, loading };
};

useDrawResult.propTypes = {
  getDrawResults: PropTypes.func,
  status: PropTypes.number,
  data: PropTypes.objectOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

export default useDrawResult;
