import bannerAcidentesPessoaisBemEstar from "./banner-acidentes-pessoais-bem-estar-dez-2024.png";
import acidentesPessoaisApresentacao from "./acidentes-pessoais-apresentacao.svg";
import acidentesPessoaisApresentacaoFlip from "./acidentes-pessoais-apresentacao-flip.svg";
import acidentesPessoaisIndenizacao from "./acidentes-pessoais-indenizacao.svg";
import acidentesPessoaisSorteio from "./acidentes-pessoais-sorteio.svg";
import acidentesPessoaisCoracao from "./acidentes-pessoais-coracao.svg";
import acidentesPessoaisOQueEstaIncluso from "./acidentes-pessoais-o-que-esta-incluso.svg";
import acidentesPessoaisVerMais from "./acidentes-pessoais-ver-mais.svg";
import acidentesPessoaisApresentacaoDireita from "./acidentes-pessoais-apresentacao-direita.svg";
import acidentesPessoaisApresentacaoEsquerda from "./acidentes-pessoais-apresentacao-esquerda.svg";
import acidentesPessoaisIconeAssistencia from "./acidentes-pessoais-icone-assistencia.svg";
import acidentesPessoaisIconeBolsa from "./acidentes-pessoais-icone-bolsa-valores.svg";
import acidentesPessoaisIconeIndenizacao from "./acidentes-pessoais-icone-indenizacao.svg";
import iconAssistenciaCestaBasica from "./icon-assistencia-cesta-basica.svg";
import iconAssistenciaFarmacia from "./icon-assistencia-farmacia.svg";
import iconAssistenciaFuneral from "./icon-assistencia-funeral.svg";
import iconIndenizacaoMorteAcidental from "./icon-indenizacao-morte-acidental.svg";
import iconReducaoCusto from "./icon-reducao-custo.svg";
import iconSorteios from "./icon-sorteios.svg";
import logoCca from "./logo-cca.png";
import logoLoterica from "./logo-loterica-fundo-branco.jpg";

export default {
  bannerAcidentesPessoaisBemEstar,
  acidentesPessoaisApresentacao,
  acidentesPessoaisApresentacaoFlip,
  acidentesPessoaisApresentacaoDireita,
  acidentesPessoaisApresentacaoEsquerda,
  acidentesPessoaisIndenizacao,
  acidentesPessoaisSorteio,
  acidentesPessoaisCoracao,
  acidentesPessoaisOQueEstaIncluso,
  acidentesPessoaisVerMais,
  acidentesPessoaisIconeAssistencia,
  acidentesPessoaisIconeBolsa,
  acidentesPessoaisIconeIndenizacao,
  iconAssistenciaCestaBasica,
  iconAssistenciaFarmacia,
  iconAssistenciaFuneral,
  iconIndenizacaoMorteAcidental,
  iconReducaoCusto,
  iconSorteios,
  logoCca,
  logoLoterica
};
