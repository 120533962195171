import React from "react";
import { Button } from "../../../components/shared/actions";
import { LINKS } from "../../../constants";

const cardData = [
  {
    title: "Pagamento Mensal",
    price: "R$ 18,90",
    plan: "Plano Mensal",
    link: `${LINKS.autoCompraBemEstar}/Mensal`,
  },
  {
    title: "Pagamento Único (12 meses)",
    price: "R$ 199",
    plan: "Plano Anual",
    link: `${LINKS.autoCompraBemEstar}/Anual`,
  },
  {
    title: "Pagamento Único (36 meses)",
    price: "R$ 500",
    plan: "Plano Trienal",
    savings: "Economia de 19,4%",
    link: `${LINKS.autoCompraBemEstar}/Trienal`,
  },
];

export const MicroSeguroAmparo = ({ sectionTitle }) => {
  return (
    <div className="div-secao" id="valores">
      <h4>{sectionTitle}</h4>
      <div className="div-cards">
        {cardData.map((card) => (
          <div className="div-card border" key={card.link}>
            <div className="div-line-a">
              <p>
                <strong>{card.title}</strong>
              </p>
            </div>
            <div className="div-line-b">
              <div className="div-line-b--a">
                <h1>{card.price}</h1>
              </div>
              {card.savings && (
                <div className="div-line-b--b">
                  <p>
                    <strong>{card.savings}</strong>
                  </p>
                </div>
              )}
            </div>
            <div className="div-line-c">
              <p>
                <strong>{card.plan}</strong>
              </p>
            </div>
            <div className="div-line-d">
              <Button className="base-btn btn-secondary" href={card.link}>
                Contratar Agora
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MicroSeguroAmparo;