import React from "react";
import { Button } from "../../../components/shared/actions";
import { LINKS } from "../../../constants";

export const MicroSeguroAmparo = ({ sectionTitle }) => {
  return (
    <div className="div-secao">
      <h4>{sectionTitle}</h4>
      <div className="div-cards">
        <div className="div-card border">
          <div className="div-line-a">
            <p>Indenização de 
              <strong> R$ 3.000,00</strong>
            </p>
          </div>
          <div className="div-line-b">
            <div className="div-line-b--c">
              <h1>R$ 40,00</h1>
            </div>
          </div>
          <div className="div-line-c">
            <p>
              <strong>Plano Anual</strong>
            </p>
          </div>
          <div className="div-line-d">
            <Button
              className="base-btn btn-c-primary"
              href={`${LINKS.autoCompraAmparo}/1`}
            >
              Contratar Agora
            </Button>
          </div>
        </div>

        <div className="div-card border">
          <div className="div-line-a">
            <p>Indenização de 
              <strong> R$ 5.000,00</strong>
            </p>
          </div>
          <div className="div-line-b">
            <div className="div-line-b--c">
              <h1>R$ 60,00</h1>
            </div>        
          </div>
          <div className="div-line-c">
            <p>
              <strong>Plano Anual</strong>
            </p>
          </div>
          <div className="div-line-d">
            <Button
              className="base-btn btn-c-primary"
              href={`${LINKS.autoCompraAmparo}/2`}
            >
              Contratar Agora
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MicroSeguroAmparo;
