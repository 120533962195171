import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { LINKS } from "@constants";
import { Button } from "../../../components/shared/actions";
import { BannerContents } from "../../../components/shared/layout";
import imagensPrevidencia from '@asserts/images/previdencia/index';

export const BannersPrestamista = (props) => {
  const { images } = props;

  return (
    <>
      <BannerContents {...props}>
        <BannerContents.Banner
          backgroundImage={images.bannerPrestamista}
          className="text-white"
          right
        >
          <Row>
            <Col xs={12} className="d-flex align-items-stretch">
              <FaFileInvoiceDollar className="d-none d-md-inline-block cvp-icon-banner" />
              <h3 className="d-md-inline-block">Seguro Dívida Zero</h3>
            </Col>
            <Col xs={12}>
              <p className="text-body-md">
                Garanta o pagamento do seu empréstimo junto à CAIXA em caso de
                morte ou invalidez total
              </p>
              <Button className="mt-3" blank secondary href={LINKS.areaLogada}>
                Já sou cliente
              </Button>
            </Col>
          </Row>
        </BannerContents.Banner>
              <BannerContents.Banner
                backgroundImage={imagensPrevidencia.bannerAssistencias}
                className="text-blue"
                left
              >
                <Row>
                  <Col xs={12} className="d-flex align-items-stretch">
                  <h3 className="d-md-inline-block title-assistencias">SUSTENTABILIDADE AO SEU ALCANCE</h3>
                  </Col>
                  <Col xs={12}>
                    <p className="text-body-md mt-0 mt-md-2 text-assistencias">
                      Assistência que transforma o mundo ao seu redor, contribua para causas sociais e
                      ambientais enquanto aproveita benefícios pesados para você. Seja a mudança que você quer ver.
                    </p>
                    <Button
                      className="mt-2"
                      blank
                      secondary
                      href={LINKS.guiaAssistencias2025}
                    >
                      Baixe o guia
                    </Button>
                  </Col>
                </Row>
              </BannerContents.Banner>
      </BannerContents>
    </>
  );
};

BannersPrestamista.defaultProps = { images: {} };

BannersPrestamista.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannersPrestamista;
