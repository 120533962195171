/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Nav, Row } from "react-bootstrap";
import { applyKey } from "@utils";
import { Link } from "@components/shared/actions";
import envs from "../../../config/envs";

const samplesPages = envs.enableComponentsRoute
  ? [{ to: "/exemplos", label: "Exemplos de Componentes" }]
  : [];

const ProductMenu = ({ onSelectHandle }) => {
  const [menuItens] = useState(
    [
      { to: "/previdencia", label: "Previdência" },
      { to: "/segurodevida", label: "Seguro de Vida" },
      { to: "/amparo", label: "Seguro Amparo" },
      { to: "/segurodividazero", label: "Seguro Dívida Zero" },
    ].concat(samplesPages)
  );

  return (
    <Row>
      <Col md={{ offset: 2 }}>
        <Nav className="mr-auto flex-column">
          {applyKey(menuItens, (item) => (
            <Nav.Link as={Link} toRoute={item.to} onClick={onSelectHandle}>
              {item.label}
            </Nav.Link>
          ))}
        </Nav>
      </Col>
    </Row>
  );
};

ProductMenu.defaultProps = {
  onSelectHandle: null,
};

ProductMenu.propTypes = {
  onSelectHandle: PropTypes.func,
};

export default ProductMenu;
