import React from "react";
import { BenefitsBlocks } from "@components/blocosCustomizados";
import { Section } from "@components/shared/pages";
import { Button } from "../../../components/shared/actions";

export const BeneficiosAmparo = ({ images, sectionTitle }) => {
  const conteudoVantagem = [
    {
      image: images.amparoBlocoA,
      title: "",
      description: (
        <p>
          Assistência funeral em caso de<br />falecimento do(a) segurado(a)<br />titular.
        </p>
      ),
    },
    {
      image: images.amparoBlocoB,
      title: "",
      description: (
        <p>
          Cesta básica em caso de<br />morte por acidente do(a)<br />segurado(a) titular.
        </p>
      ),
    },
    {
      image: images.amparoBlocoC,
      title: "",
      description: <p>Indenização por<br />morte.</p>,
    },
  ];

  return (
    <>
      <Section.Content className="text-center text-sm-center title-size">
        <p className="basic-color-blue extra-title">
          Saiba mais sobre{" "}
          <strong>Seguro Amparo</strong>
        </p>
      </Section.Content>
      <BenefitsBlocks sectionTitle={sectionTitle} items={conteudoVantagem} />
      <Section.Content className="text-center text-sm-center btn-espaco">
        <Button blank rounded transparent toRoute="/acidentespessoais">
          Saiba Mais!
        </Button>
      </Section.Content>
    </>
  );
};

BeneficiosAmparo.propTypes = {
  ...Section.specializedProps,
};

export default BeneficiosAmparo;
