import banner from './banner.png';
import bannerVida from './banner-bg-vida.jpg';
import bannerAcidentesPessoais from './banner-acidentes-pessoais.png';
import bannerAcidentesPessoaisBemEstar from './banner-acidentes-pessoais-bem-estar-dez-2024.png';
import bannerBemEstarCampanha from './banner-bem-estar-campanha.png';
import vidaBlocoEntendaInfo from './vida-bloco-entenda-info.svg';
import vidaImagemCoracao from './vida-imagem-coracao.svg';
import vidaImagemBeneficioGratuito from './vida-imagem-beneficio-gratuito.svg';
import vidaImagemCabeOrcamento from './vida-imagem-cabe-orcamento.svg';
import vidaImagemSorteios from './vida-imagem-sorteios.svg';
import vidaSaibaMaisAssistencia from './vida-saiba-mais-assistencia.svg';
import vidaImagemCobertura from './vida-imagem-cobertura.svg';
import vidaImagemAssistencia from './vida-imagem-assistecias.svg';
import amparoBlocoA from './amparo-bloco-a.svg';
import amparoBlocoB from './amparo-bloco-b.svg';
import amparoBlocoC from './amparo-bloco-c.svg';
import bemEstarBlocoA from './bem-estar-bloco-a.svg';
import bemEstarBlocoB from './bem-estar-bloco-b.svg';
import bemEstarBlocoC from './bem-estar-bloco-c.svg';
import amparoAssistente from './amparo-assistente.svg';

import vidaBtnChat from './vida-btn-chat.svg';
import vidaBtnOnline from './vida-btn-online.svg';
import ServicoSinistro from './sinistro.svg';

const imagensVida = {
  banner,
  bannerVida,
  bannerBemEstarCampanha,
  bannerAcidentesPessoais,
  bannerAcidentesPessoaisBemEstar,
  vidaBlocoEntendaInfo,
  vidaImagemCoracao,
  vidaImagemBeneficioGratuito,
  vidaImagemCabeOrcamento,
  vidaImagemSorteios,
  vidaSaibaMaisAssistencia,
  vidaImagemCobertura,
  vidaImagemAssistencia,

  amparoBlocoA,
  amparoBlocoB,
  amparoBlocoC,
  bemEstarBlocoA,
  bemEstarBlocoB,
  bemEstarBlocoC,
  amparoAssistente,
  vidaBtnChat,
  vidaBtnOnline,
  ServicoSinistro,
};

export default imagensVida;
