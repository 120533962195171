import React from "react";
import PropTypes from "prop-types";
import { InputControl } from "../../../../../components/shared/forms";

const CityInput = ({ label, cidade, formikBag, ...props }) => (
  <InputControl
    inputStyle={{ minHeight: "50px" }}
    controlId="formSinistro.reclamante.cidade"
    label={label}
    placeHolder="Digite a cidade"
    name="reclamante.cidade"
    value={cidade}
    {...formikBag}
    {...props}
  />
);

CityInput.propTypes = {
  cidade: PropTypes.string.isRequired,
  formikBag: PropTypes.objectOf(Object).isRequired,
};

export default CityInput;
